<template>
    <div>
        <Navbar page="Teste de legítimo interesse " link="/entregaveis/legitimoInteresse" nameLink="Legítimo interesse" pdf="/orientacoes_teste_legitimo_interesse.pdf" />

        <nav aria-label="Progress" class="">
            <ol role="list" class="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
                <li v-for="(aba, index) in abas" :key="index" class="relative md:flex md:flex-1"
                    :class="{ 'bg-yellow-400': aba.visible, 'bg-blue-500': aba.semNumero }" :style="{
                        'background-color': aba.visible ? $store.state.colorPrincipal : aba.semNumero ? $store.state.colorSecundaria : '',
                        'color': aba.visible ? invertColor($store.state.colorPrincipal, true) : aba.semNumero ? invertColor($store.state.colorSecundaria, true) : ''
                    }
                        ">
                    <a @click="setAba(index)" href="#" class="flex items-center px-6 py-4 text-sm font-medium "
                        aria-current="step">
                        <span v-if="!aba.semNumero"
                            class="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-black-600">
                            <span v-show="!aba.visible" class="text-black-600">{{ index + 1 }}</span>
                            <svg v-show="aba.visible" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>
                        </span>
                        <span class="ml-4 text-sm font-medium text-black-600"
                            :class="{ 'text-white': aba.semNumero }">{{ aba.nome }}</span>
                    </a>
                </li>
            </ol>
        </nav>

        <div class="mx-1 md:mx-5 my-8">
            <div class="mt-5 md:mt-0">
                <form action="#" method="POST">

                    <div v-show="abas[0].visible" class="px-5 pb-4">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="rounded bg bg-gray-100 p-3 flex flex-col gap-5">
                                <label class="block text-sm font-medium">
                                    <b>Fundamentação legal:</b> Princípio da finalidade (art. 6º, I, lgpd) e Art. 10, caput,
                                    lgpd – “O legítimo interesse do controlador somente poderá fundamentar tratamento de
                                    dados
                                    pessoais para finalidades legítimas, consideradas a partir de situações concretas, que
                                    incluem, mas não se limitam a...
                                </label>
                                <label class="block text-sm font-medium">
                                    <b>Objetivo:</b> Identificar a natureza dos dados pessoais e a aplicabilidade da
                                    hipótese legal do legítimo interesse ao tratamento dos dados pessoais, mediante a
                                    avaliação da
                                    legitimidade do interesse, ou seja, se este é compatível com o ordenamento jurídico,
                                    baseado em uma situação concreta e vinculado a uma finalidade legítima, específica e
                                    explícita.
                                </label>
                                <label class="block text-sm font-medium">
                                    <b>Orientações gerais:</b> As informações devem ser apresentadas de forma clara,
                                    objetiva e precisa, com todos os detalhes necessários para permitir a compreensão e o
                                    delineamento adequados dos objetivos do tratamento
                                </label>
                            </div>
                            <div class=" mt-4">

                                <label for="comentarios" class="block text-sm font-medium">
                                    <b>1.1 - </b>
                                    Quanto a natureza dos dados pessoais
                                </label>
                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            a. Existe tratamento de dados pessoais sensíveis? Em caso afirmativo, o
                                            tratamento não pode ser realizado com base na hipótese legal do
                                            legítimo interesse
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq1_a_sim" 
                                                v-model="form.finalidade.q1_a" 
                                                :value="true">
                                            <label for="finalidadeq1_a_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq1_a_nao" 
                                                v-model="form.finalidade.q1_a" 
                                                :value="false">
                                            <label for="finalidadeq1_a_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.finalidade.q1_a_obs" 
                                            name="finalidadeq1_a_obs"
                                            id="finalidadeq1_a_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>
                            </div>

                            <div class=" mt-4">

                                <label for="comentarios" class="block text-sm font-medium">
                                    <b>1.2 -</b>
                                    Quanto a existência de dados de crianças e adolescentes
                                </label>
                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            a. Serão tratados dados de crianças e adolescentes? Se “sim”, indique o
                                            que foi considerado como melhor interesse dos titulares e os critérios
                                            utilizados para a ponderação entre os interesses do controlador ou de
                                            terceiro e os direitos dos titulares
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq2_a_sim" 
                                                v-model="form.finalidade.q2_a" 
                                                :value="true">
                                            <label for="finalidadeq2_a_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq2_a_nao" 
                                                v-model="form.finalidade.q2_a" 
                                                :value="false">
                                            <label for="finalidadeq2_a_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.finalidade.q2_a_obs" 
                                            name="finalidadeq2_a_obs"
                                            id="finalidadeq2_a_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            b. O tratamento gera riscos ou impactos desproporcionais e excessivos,
                                            considerando a condição da criança e do adolescente como sujeito de
                                            direitos?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq2_b_sim" 
                                                v-model="form.finalidade.q2_b" 
                                                :value="true">
                                            <label for="finalidadeq2_b_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq2_b_nao" 
                                                v-model="form.finalidade.q2_b" 
                                                :value="false">
                                            <label for="finalidadeq2_b_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.finalidade.q2_b_obs" 
                                            name="finalidadeq2_b_obs"
                                            id="finalidadeq2_b_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            c. O controlador possui uma relação direta com os titulares? Caso “não”
                                            deve avaliar alternativas menos invasivas bem como a implementação
                                            as medidas de segurança e de mitigação adequadas.
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq2_c_sim" 
                                                v-model="form.finalidade.q2_c" 
                                                :value="true">
                                            <label for="finalidadeq2_c_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq2_c_nao" 
                                                v-model="form.finalidade.q2_c" 
                                                :value="false">
                                            <label for="finalidadeq2_c_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.finalidade.q2_c_obs" 
                                            name="finalidadeq2_c_obs"
                                            id="finalidadeq2_c_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            d. O tratamento visa assegurar a proteção de direitos e interesses dos
                                            titulares ou viabilizar a prestação de serviços que os beneficiem? Caso
                                            “não” deve avaliar alternativas menos invasivas bem como a
                                            implementação as medidas de segurança e de mitigação adequadas.
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq2_d_sim" 
                                                v-model="form.finalidade.q2_d" 
                                                :value="true">
                                            <label for="finalidadeq2_d_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq2_d_nao" 
                                                v-model="form.finalidade.q2_d" 
                                                :value="false">
                                            <label for="finalidadeq2_d_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.finalidade.q2_d_obs" 
                                            name="finalidadeq2_d_obs"
                                            id="finalidadeq2_d_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>
                            </div>

                            <div class=" mt-4">

                                <label for="comentarios" class="block text-sm font-medium">
                                    <b>1.3 - </b>
                                    Quanto a Interesse e finalidades legítimas
                                </label>
                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            a. Existe benefício ou proveito que resulta do tratamento de dados
                                            pessoais para o controlador ou terceiro?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq3_a_sim" 
                                                v-model="form.finalidade.q3_a" 
                                                :value="true">
                                            <label for="finalidadeq3_a_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq3_a_nao" 
                                                v-model="form.finalidade.q3_a" 
                                                :value="false">
                                            <label for="finalidadeq3_a_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.finalidade.q3_a_obs" 
                                            name="finalidadeq3_a_obs"
                                            id="finalidadeq3_a_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            b. O interesse é compatível com princípios, normas jurídicas e direitos
                                            fundamentais? Aplicam-se ao caso e não se aplicam às hipóteses legais
                                            que vedam ou impeçam a realização do tratamento?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq3_b_sim" 
                                                v-model="form.finalidade.q3_b" 
                                                :value="true">
                                            <label for="finalidadeq3_b_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq3_b_nao" 
                                                v-model="form.finalidade.q3_b" 
                                                :value="false">
                                            <label for="finalidadeq3_b_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.finalidade.q3_b_obs" 
                                            name="finalidadeq3_b_obs"
                                            id="finalidadeq3_b_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            c. A finalidade é legítima, específica e explícita? Se “sim” explique qual a
                                            finalidade do tratamento
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq3_c_sim" 
                                                v-model="form.finalidade.q3_c" 
                                                :value="true">
                                            <label for="finalidadeq3_c_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq3_c_nao" 
                                                v-model="form.finalidade.q3_c" 
                                                :value="false">
                                            <label for="finalidadeq3_c_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.finalidade.q3_c_obs" 
                                            name="finalidadeq3_c_obs"
                                            id="finalidadeq3_c_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>
                            </div>

                            <div class="mt-4">

                                <label for="comentarios" class="block text-sm font-medium">
                                    <b>1.4 - </b>
                                    Quanto a Situação concreta
                                </label>
                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            a. O interesse é baseado em uma situação clara, concreta e não
                                            especulativa? Se “sim”, explique qual é essa situação concreta, de forma
                                            detalhada e qual o contexto em que é realizado o tratamento?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq4_a_sim" 
                                                v-model="form.finalidade.q4_a" 
                                                :value="true">
                                            <label for="finalidadeq4_a_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="finalidadeq4_a_nao" 
                                                v-model="form.finalidade.q4_a" 
                                                :value="false">
                                            <label for="finalidadeq4_a_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.finalidade.q4_a_obs" 
                                            name="finalidadeq4_a_obs"
                                            id="finalidadeq4_a_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>
                            </div>
                        </div>

                    </div>
                    <div v-show="abas[1].visible" class="px-5 pb-4">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="rounded bg bg-gray-100 p-3 flex flex-col gap-5">
                                <label class="block text-sm font-medium">
                                    <b>Fundamentação legal:</b> Princípio da necessidade (art. 6º, iii, lgpd) e art. 10, §1º, lgpd – “§ 1º Quando o tratamento for baseado no legítimo interesse do controlador, somente os
                                    dados pessoais estritamente necessários para a finalidade pretendida poderão ser tratados.”
                                </label>
                                <label class="block text-sm font-medium">
                                    <b>Objetivo:</b> Identificar se o tratamento baseado no legítimo interesse é necessário para atingir as finalidades do passo anterior, além de ponderar medidas de minimização do uso
                                    de dados pessoais.
                                </label>
                                <label class="block text-sm font-medium">
                                    <b>Orientações gerais:</b>  Nessa fase é importante avaliar a existência de formas menos intrusivas para realizar o tratamento, além de analisar se é possível atingir a finalidade de
                                    uma forma menos onerosa e com menores riscos ao titular. Outra observação importante é que, caso haja mais de uma finalidade descrita na Parte 1, recomenda- -se que seja
                                    feito outro teste para fundamentar a outra finalidade
                                </label>
                            </div>
                            <div class=" mt-4">
                                <label for="comentarios" class="block text-sm font-medium">
                                    <b>2.1 - </b>
                                    Quanto ao Tratamento e a finalidade pretendida
                                </label>
                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            a. O tratamento é necessário para atingir os interesses analisados no
                                            passo anterior?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="necessidadeq1_a_sim" 
                                                v-model="form.necessidade.q1_a" 
                                                :value="true">
                                            <label for="necessidadeq1_a_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="necessidadeq1_a_nao" 
                                                v-model="form.necessidade.q1_a" 
                                                :value="false">
                                            <label for="necessidadeq1_a_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.necessidade.q1_a_obs" 
                                            name="necessidadeq1_a_obs"
                                            id="necessidadeq1_a_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            b. É possível usar outros meios razoáveis para atingir a mesma finalidade
                                            de forma menos intrusiva para o titular?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="necessidadeq1_b_sim" 
                                                v-model="form.necessidade.q1_b" 
                                                :value="true">
                                            <label for="necessidadeq1_b_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="necessidadeq1_b_nao" 
                                                v-model="form.necessidade.q1_b" 
                                                :value="false">
                                            <label for="necessidadeq1_b_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.necessidade.q1_b_obs" 
                                            name="necessidadeq1_b_obs"
                                            id="necessidadeq1_b_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            c. O tratamento é proporcional e adequado para a finalidade descrita?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="necessidadeq1_c_sim" 
                                                v-model="form.necessidade.q1_c" 
                                                :value="true">
                                            <label for="necessidadeq1_c_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="necessidadeq1_c_nao" 
                                                v-model="form.necessidade.q1_c" 
                                                :value="false">
                                            <label for="necessidadeq1_c_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.necessidade.q1_c_obs" 
                                            name="necessidadeq1_c_obs"
                                            id="necessidadeq1_c_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>
                            </div>

                            <div class=" mt-4">
                                <label for="comentarios" class="block text-sm font-medium">
                                    <b>2.2 - </b>
                                    Quanto a minimização dos dados pessoais tratados
                                </label>
                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            a.  Estão sendo utilizados apenas os dados estritamente necessários para
                                            atingir à finalidade pretendida?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="necessidadeq2_a_sim" 
                                                v-model="form.necessidade.q2_a" 
                                                :value="true">
                                            <label for="necessidadeq2_a_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="necessidadeq2_a_nao" 
                                                v-model="form.necessidade.q2_a" 
                                                :value="false">
                                            <label for="necessidadeq2_a_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.necessidade.q2_a_obs" 
                                            name="necessidadeq2_a_obs"
                                            id="necessidadeq2_a_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            b. Existem formas menos intrusivas, menos onerosas ou com menores
                                            riscos ao titular que poderiam ser utilizadas para atingir a mesma
                                            finalidade?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="necessidadeq2_b_sim" 
                                                v-model="form.necessidade.q2_b" 
                                                :value="true">
                                            <label for="necessidadeq2_b_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="necessidadeq2_b_nao" 
                                                v-model="form.necessidade.q2_b" 
                                                :value="false">
                                            <label for="necessidadeq2_b_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.necessidade.q2_b_obs" 
                                            name="necessidadeq2_b_obs"
                                            id="necessidadeq2_b_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>
                            </div>
                            
                        </div>
                    </div>

                    <div v-show="abas[2].visible" class="px-5 pb-4">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="rounded bg bg-gray-100 p-3 flex flex-col gap-5">
                                <label class="block text-sm font-medium">
                                    <b>Fundamentação legal:</b> Art. 7º, ix, lgpd - “quando necessário para atender aos interesses legítimos do controlador ou de terceiro, exceto no caso de prevalecerem direitos e
                                    liberdades fundamentais do titular que exijam a proteção dos dados pessoais”; Art. 10, ii, lgpd – “proteção, em relação ao titular, do exercício regular de seus direitos ou prestação
                                    de serviços que o beneficiem, respeitadas as legítimas expectativas dele e os direitos e liberdades fundamentais, nos termos desta Lei”; e Art. 10, §2º, lgpd - § 2º - “O controlador
                                    deverá adotar medidas para garantir a transparência do tratamento de dados baseado em seu legítimo interess
                                </label>

                                <label class="block text-sm font-medium">
                                    <b>Objetivo:</b> Avaliar os riscos e os impactos sobre os direitos dos titulares dos dados com base no interesse e finalidades identificados nas fases anteriores, além de balancear esses
                                    riscos com as salvaguardas a serem adotadas e com a garantia de acesso claro e preciso aos titulares acerca das informações relativas ao tratamento dos seus dados.
                                </label>

                                <label class="block text-sm font-medium">
                                    <b>Orientações gerais:</b> Nessa fase é fundamental adotar a perspectiva do titular, a fim de assegurar que as suas legítimas expectativas e seus direitos e liberdades fundamentais
                                    sejam respeitados. É importante colocar na balança os interesses do controlador ou de terceiro e dos titulares, considerando as especificidades da situação concreta, tal como
                                    quando o tratamento abranger dados de crianças e adolescentes. Por isso, a fim de obter uma análise mais precisa, é importante adotar uma ampla gama de pontos de vista
                                    possíveis. Cabe destacar que a existência de um possível risco ou impacto negativo sobre os titulares não afasta, por si só, o tratamento dos dados pessoais com base no legítimo
                                    interesse. O que a lgpd exige não é o impacto zero, mas, sim, que os eventuais impactos sejam minimizados e levados em consideração na adoção de salvaguardas a fim de
                                    assegurar que, no caso concreto, os interesses que justificam a realização do tratamento são compatíveis com o respeito aos direitos e as liberdades fundamentais do titular.
                                </label>
                            </div>
                            <div class=" mt-4">
                                <label for="comentarios" class="block text-sm font-medium">
                                    <b>3.1 - </b>
                                    Quanto à Legítima expectativa
                                </label>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            a. O tratamento dos dados pessoais para a finalidade pretendida é
                                            razoavelmente esperado pelos titulares, considerando o contexto em que
                                            é realizado?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq1_a_sim" 
                                                v-model="form.balanceamento.q1_a" 
                                                :value="true">
                                            <label for="balanceamentoq1_a_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq1_a_nao" 
                                                v-model="form.balanceamento.q1_a" 
                                                :value="false">
                                            <label for="balanceamentoq1_a_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.balanceamento.q1_a_obs" 
                                            name="balanceamentoq1_a_obs"
                                            id="balanceamentoq1_a_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            b. A avaliação quanto à legítima expectativa deve levar em
                                            consideração, entre outros, os seguintes fatores relevantes: Existe uma
                                            relação prévia do controlador com o titular?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq1_b_sim" 
                                                v-model="form.balanceamento.q1_b" 
                                                :value="true">
                                            <label for="balanceamentoq1_b_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq1_b_nao" 
                                                v-model="form.balanceamento.q1_b" 
                                                :value="false">
                                            <label for="balanceamentoq1_b_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.balanceamento.q1_b_obs" 
                                            name="balanceamentoq1_b_obs"
                                            id="balanceamentoq1_b_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            c. Os dados foram coletados diretamente do titular, de fontes públicas ou
                                            foram obtidos por meio de compartilhamento realizado por terceiros?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap gap-2 col-span-12 lg:col-span-4 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq1_c_titular" 
                                                v-model="form.balanceamento.q1_c" 
                                                :value="'titular'">
                                            <label for="balanceamentoq1_c_titular">
                                                diretamente do titular
                                            </label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq1_c_publicas" 
                                                v-model="form.balanceamento.q1_c" 
                                                :value="'publicas'">
                                            <label for="balanceamentoq1_c_publicas">
                                                de fontes públicas
                                            </label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq1_c_terceiros" 
                                                v-model="form.balanceamento.q1_c" 
                                                :value="'terceiros'">
                                            <label for="balanceamentoq1_c_terceiros">
                                                por terceiros
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-3 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.balanceamento.q1_c_obs" 
                                            name="balanceamentoq1_c_obs"
                                            id="balanceamentoq1_c_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            d. Qual o contexto e o período da coleta dos dados pessoais?
                                        </label>
                                    </div>
                                    <div class="col-span-12 lg:col-span-7 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.balanceamento.q1_d_obs" 
                                            name="balanceamentoq1_d_obs"
                                            id="balanceamentoq1_d_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            e. A finalidade original da coleta é compatível com o tratamento baseado
                                            no legítimo interesse?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq1_e_sim" 
                                                v-model="form.balanceamento.q1_e" 
                                                :value="true">
                                            <label for="balanceamentoq1_e_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq1_e_nao" 
                                                v-model="form.balanceamento.q1_e" 
                                                :value="false">
                                            <label for="balanceamentoq1_e_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>

                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.balanceamento.q1_e_obs" 
                                            name="balanceamentoq1_e_obs"
                                            id="balanceamentoq1_e_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>
                            </div>
                            <div class=" mt-4">
                                <label for="comentarios" class="block text-sm font-medium">
                                    <b>3.2 - </b>
                                    Quanto aos riscos e impactos aos direitos e liberdades fundamentais
                                </label>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-6 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            a. De que forma os titulares de dados pessoais serão impactados pelo tratamento?
                                        </label>
                                    </div>

                                    <div class="col-span-12 lg:col-span-6 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.balanceamento.q2_a_obs" 
                                            name="balanceamentoq2_a_obs"
                                            id="balanceamentoq2_a_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-6 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            b. Direitos e garantias fundamentais como liberdade de expressão,
                                            locomoção, não discriminação, intimidade, integridade física e moral
                                            podem ser afetados com o tratamento?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq2_b_sim" 
                                                v-model="form.balanceamento.q2_b" 
                                                :value="true">
                                            <label for="balanceamentoq2_b_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq2_b_nao" 
                                                v-model="form.balanceamento.q2_b" 
                                                :value="false">
                                            <label for="balanceamentoq2_b_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>

                                    <div class="col-span-12 lg:col-span-4 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.balanceamento.q2_b_obs" 
                                            name="balanceamentoq2_b_obs"
                                            id="balanceamentoq2_b_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            c. Quais são os riscos em potencial sobre os titulares?
                                        </label>
                                    </div>

                                    <div class="col-span-12 lg:col-span-7 h-full py-1">
                                        <textarea 
                                            placeholder="trazer do item xxxx da analise de risco desta atividade" 
                                            v-model="form.balanceamento.q2_c_obs" 
                                            name="balanceamentoq2_c_obs"
                                            id="balanceamentoq2_c_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            d. Os direitos e liberdades fundamentais dos titulares prevalecem sobre
                                            os interesses do controlador ou de terceiro?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq2_d_sim" 
                                                v-model="form.balanceamento.q2_d" 
                                                :value="true">
                                            <label for="balanceamentoq2_d_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq2_d_nao" 
                                                v-model="form.balanceamento.q2_d" 
                                                :value="false">
                                            <label for="balanceamentoq2_d_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>

                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.balanceamento.q2_d_obs" 
                                            name="balanceamentoq2_d_obs"
                                            id="balanceamentoq2_d_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>
                            </div>
                            <div class=" mt-4">
                                <label for="comentarios" class="block text-sm font-medium">
                                    <b>3.3 - </b>
                                    Salvaguardas e mecanismos de opt-out e de oposição
                                </label>
                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            a. Existem medidas para mitigar os riscos identificados?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq3_a_sim" 
                                                v-model="form.balanceamento.q3_a" 
                                                :value="true">
                                            <label for="balanceamentoq3_a_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq3_a_nao" 
                                                v-model="form.balanceamento.q3_a" 
                                                :value="false">
                                            <label for="balanceamentoq3_a_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.balanceamento.q3_a_obs" 
                                            name="balanceamentoq3_a_obs"
                                            id="balanceamentoq3_a_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            b. São adotadas medidas de transparência para disponibilizar
                                            informações claras e acessíveis sobre o tratamento de dados e os
                                            agentes envolvidos?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq3_b_sim" 
                                                v-model="form.balanceamento.q3_b" 
                                                :value="true">
                                            <label for="balanceamentoq3_b_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq3_b_nao" 
                                                v-model="form.balanceamento.q3_b" 
                                                :value="false">
                                            <label for="balanceamentoq3_b_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.balanceamento.q3_b_obs" 
                                            name="balanceamentoq3_b_obs"
                                            id="balanceamentoq3_b_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>

                                <section class="grid grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                    <div class="col-span-12 lg:col-span-5 bg-gray-100 rounded-md p-4">
                                        <label class="text-sm">
                                            c. Será disponibilizado canal por meio do qual os titulares podem exercer
                                            os direitos em especial os de se descadastrar ou eliminação de seus
                                            dados pessoais?
                                        </label>
                                    </div>

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-12 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq3_c_sim" 
                                                v-model="form.balanceamento.q3_c" 
                                                :value="true">
                                            <label for="balanceamentoq3_c_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="balanceamentoq3_c_nao" 
                                                v-model="form.balanceamento.q3_c" 
                                                :value="false">
                                            <label for="balanceamentoq3_c_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-12 lg:col-span-5 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.balanceamento.q3_c_obs" 
                                            name="balanceamentoq3_c_obs"
                                            id="balanceamentoq3_c_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                    <div v-show="abas[3].visible" class="px-5 pb-4">
                        <div class="grid grid-cols-1 gap-5">
                            <div class="rounded bg bg-gray-100 p-3 flex flex-col gap-5">
                                <label class="block text-sm font-medium">
                                    <b>Objetivo:</b> Analisar as respostas das Partes 1, 2 e 3 para concluir se pode ou não
                                    aplicar a hipótese legal do legítimo interesse.
                                </label>
                            </div>
                            <div class=" mt-4">
                                <label for="comentarios" class="block text-sm font-medium">
                                    <b>4.1</b>
                                    É possível utilizar o legítimo interesse nesse tratamento de dados?
                                </label>
                                <section class="grid grid-cols-1 md:grid-cols-12 lg:grid-cols-12 items-center mt-3 gap-2"
                                    :class="$store.state.user.cliente ? 'opacity-50' : ''">

                                    <div
                                        class="flex flex-wrap justify-around gap-[8px] col-span-1 md:col-span-2 lg:col-span-2 bg-gray-100 rounded-md p-4 h-full">
                                        <section class="flex items-center gap-2">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="conclusaoq1_a_sim" 
                                                v-model="form.conclusao.q1_a" 
                                                :value="true">
                                            <label for="conclusaoq1_a_sim">Sim</label>
                                        </section>
                                        <section class="flex items-center gap-2 ">
                                            <input 
                                                :disabled="$store.state.user.cliente ? true : false" 
                                                type="radio"
                                                class="form-radio" 
                                                id="conclusaoq1_a_nao" 
                                                v-model="form.conclusao.q1_a" 
                                                :value="false">
                                            <label for="conclusaoq1_a_nao">
                                                Não
                                            </label>
                                        </section>
                                    </div>


                                    <div class="col-span-1 md:col-span-10 lg:col-span-10 h-full py-1">
                                        <textarea 
                                            placeholder="Observações" 
                                            v-model="form.conclusao.q1_a_obs" 
                                            name="conclusaoq1_a_obs"
                                            id="conclusaoq1_a_obs"
                                            class="focus:ring-yellow-400 h-full focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-2xl">
                                        </textarea>
                                    </div>
                                </section>
                            </div>

                            <div class="grid grid-cols-12 items-start mt-3 gap-2"
                                :class="$store.state.user.cliente ? 'opacity-50' : ''">
                                <section class="col-span-12 md:col-span-3 lg:col-span-3">
                                    <label for="comentarios" class="block text-sm font-medium">
                                        Data do Teste:
                                    </label>
                                    <input 
                                        v-model="form.conclusao.dataTeste" 
                                        type="date" 
                                        class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl" />
                                </section>
                                <section class="col-span-12 md:col-span-4 lg:col-span-4">
                                    <label for="comentarios" class="block text-sm font-medium">
                                        Indique o Local:

                                    </label>
                                    <textarea 
                                        placeholder="Campo Aberto" 
                                        v-model="form.conclusao.indiqueLocal" 
                                        rows="2"
                                        name="indiqueLocal" id="indiqueLocal"
                                        class="mt-2 focus:ring-yellow-400 focus:border-yellow-400 block w-full shadow-sm sm:text-sm border-gray-300 rounded-3xl">
                                    </textarea>
                                </section>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-2">
                        <div class="px-5 py-3 text-left sm:px-6">
                            <button @click="$router.back()" type="button"
                                class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                                Voltar
                            </button>
                        </div>

                        <div class="px-5 py-3 text-right sm:px-6">
                            <button @click="save()" type="button"
                                class="inline-flex justify-center py-2 px-6 m-2 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-600">
                                Salvar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>


    </div>
</template>

<script>
// import vueDropzone from 'vue2-dropzone';
// import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import moment from 'moment';

export default {
    components: {
        // vueDropzone
    },
    data() {
        return {
            route_navigation: 'entregaveis/legitimoInteresse',
            atividades: {},
            impacto_titular: [],
            form :{
                atividade: false,
                finalidade: {
                    q1_a: null,
                    q1_a_obs: '',

                    q2_a: null,
                    q2_b: null,
                    q2_c: null,
                    q2_d: null,
                    q2_a_obs: '',
                    q2_b_obs: '',
                    q2_c_obs: '',
                    q2_d_obs: '',

                    q3_a: null,
                    q3_b: null,
                    q3_c: null,
                    q3_a_obs: '',
                    q3_b_obs: '',
                    q3_c_obs: '',

                    q4_a: null,
                    q4_a_obs: '',
                },
                necessidade: {
                    q1_a: null,
                    q1_b: null,
                    q1_c: null,
                    q1_a_obs: '',
                    q1_b_obs: '',
                    q1_c_obs: '',

                    q2_a: null,
                    q2_b: null,
                    q2_a_obs: '',
                    q2_b_obs: '',
                },
                balanceamento: {
                    q1_a: null,
                    q1_b: null,
                    q1_c: '',
                    q1_e: null,
                    q1_a_obs: '',
                    q1_b_obs: '',
                    q1_c_obs: '',
                    q1_d_obs: '',
                    q1_e_obs: '',

                    q2_b: null,
                    q2_d: null,
                    q2_a_obs: '',
                    q2_b_obs: '',
                    q2_c_obs: '',
                    q2_d_obs: '',

                    q3_a: null,
                    q3_b: null,
                    q3_c: null,
                    q3_a_obs: '',
                    q3_b_obs: '',
                    q3_c_obs: '',
                },
                conclusao: {
                    q1_a: null,
                    q1_a_obs: '',
                    dataTeste: '',
                    indiqueLocal: '',
                },
                dataFinalizado: null,
                resultado: '',
                porcentagem: 0,
            },
            abas: [
                {
                    nome: 'Finalidade',
                    visible: true,
                },
                {
                    nome: 'Necessidade',
                    visible: false,
                },
                {
                    nome: 'Balanceamento e Salvaguardas',
                    visible: false,
                },
                {
                    nome: 'Conclusão',
                    visible: false,
                },
            ],
            moment
        }
    },
    methods: {
        setAba(index) {
            this.abas.forEach((a, i) => {
                a.visible = false;
                if (i === index) a.visible = true;
            });
        },
        verificarVerdadeiros(obj){
            let count = 0;

            for (let key in obj) {
                if (typeof obj[key] === 'boolean' && obj[key] === true) {
                    count++;
                } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                    for (let nestedKey in obj[key]) {
                        if (typeof obj[key][nestedKey] === 'boolean' && obj[key][nestedKey] === true) {
                            count++;
                        }
                    }
                }
            }
            return count
        },
        async save() {
            const totalVerdadeiro = this.verificarVerdadeiros(this.form.finalidade) 
            + this.verificarVerdadeiros(this.form.necessidade) 
            + this.verificarVerdadeiros(this.form.balanceamento)
            + this.verificarVerdadeiros(this.form.conclusao)
            + (this.form.balanceamento.q1_c === 'publicas' ? 1 : 0);

            const porcentagem = (totalVerdadeiro * 100)/24
            let resultado = '';
            if(porcentagem<70){
                resultado = 'Legítimo interesse não verificado. É necessário rever a base legal para o tratamento dos dados ou implementar medidas para atender aos requisitos da LGPD.'
            }else if(porcentagem <99){
                resultado = 'Legítimo interesse é viável se corrigidas algumas incongruências. É recomendado realizar ajustes nas práticas de tratamento de dados para garantir a conformidade com a LGPD. As respostas "NÃO" devem ser analisadas individualmente para identificar as áreas que requerem atenção.'
            }else if(porcentagem===100){
                resultado = 'Legítimo interesse presente. A empresa demonstra estar em conformidade com os requisitos legais para o tratamento de dados com base no legítimo interesse.'
            }
            this.form.porcentagem = porcentagem;
            this.form.resultado = resultado;
            this.$confirm({
                title: 'Teste finalizado',
                message: resultado,
                button: {
                    no: 'Cancelar',
                    yes: 'Salvar',
                },
                callback: async confirm => {
                    if(confirm){
                        const method = this.form._id ? 'put' : 'post'; 
                        this.form.dataFinalizado = new Date();

                        await this.$http[method](`/v1/legitimoInteresse`, this.form)
                        .then((res)=>{
                            this.atividades.legitimoInteresse = res?.data?.id || null
                        }).catch((e)=>{
                            this.$vToastify.error(e.response?.data?.err);
                        }) 

                        if(!this.atividades.legitimoInteresse) {
                            this.$vToastify.error('Erro ao vincular legítimo interesse.');
                            return
                        }
                        const req = await this.$http.put(`/v1/atividades`, this.atividades);
                         if (req.data.success) {
                            this.$vToastify.success("Salvo com sucesso!");
                            this.$router.push({ path: '/entregaveis/legitimoInteresse' });
                        } else {
                            this.$vToastify.error(req.data.err);
                        }
                    }
                }
            });
        },
    },
    async beforeMount() {
        let loader = this.$loading.show({
            container: null,
            canCancel: true
        });
        
        const id = this.$route.params.id;
        if (id) {
            await this.$http.get(`/v1/atividades/${id}`)
            .then((res)=>{
                this.atividades = res.data
                this.form.atividade = id;
            }).catch(()=>{
                this.$router.push({ path: '/entregaveis/legitimoInteresse' });
            })

            if(this.atividades.legitimoInteresse){
                this.form._id = this.atividades.legitimoInteresse;
                await this.$http.get(`/v1/legitimoInteresse/${this.form._id}`)
                .then((res)=>{
                    if(res.data){
                        Object.assign(this.form, res.data)
                    }
                }).catch((e)=>{
                    console.log('err:', e.response?.data?.err)
                })
            }
            
            await this.$http.get(`/v1/riscos/atividade/${id}`)
            .then((res)=>{
                this.impacto_titular = res.data?.impacto_titular || []
            }).catch(()=>{
                this.impacto_titular = []
            })

            let analiseRiscos = ''
            const qtdImpactos = this.impacto_titular.length || 0;
            for(let i=0;i<qtdImpactos;i++){
                const descricao = this.impacto_titular[i].descricao

                if((i+1)<qtdImpactos){
                    analiseRiscos += descricao + ', '
                }else{
                    analiseRiscos += descricao
                }
            }
            if(!this.form.balanceamento.q2_c_obs.trim()){
                this.form.balanceamento.q2_c_obs = analiseRiscos;
            }
            
        }
        loader.hide();
    },
}
</script>